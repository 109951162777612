import React, { useEffect, useState } from "react";
import Announcments from "../components/Announcments";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import Product from "../components/Product";
import NewsLetter from "../components/NewsLetter";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { mobile } from "../Responsive";

import mainbackground from "../assets/download.jpg";

const Container = styled.div`
  scroll-behavior: smooth;

  background-position: center;
  background-size: cover;
`;
const Title = styled.h1`
  margin: 20px 20px;
`;
const FilterContainer = styled.div`
  margin: 20px;
  display: flex;
  justify-content: space-between;
`;
const Filter = styled.div`
  font-size: 20px;
  font-weight: 600;
`;
const FilterText = styled.span`
  margin-right: 20px;
`;
const Select = styled.select`
  padding: 10px;
  font-size: 15px;
  margin-right: 10px;

  &:hover {
    box-shadow: 3px 3px 2px -1px rgba(0, 0, 0, 0.2);
  }
  ${mobile({
    marginTop: "5px",
    width: "70%",
    marginLeft: "2px",
  })}
`;
const Options = styled.option``;

function ProductList(props) {
  const location = useLocation();
  let cat = location.pathname.split("/")[2];

  if (cat === "all") cat = null;

  //filters logic
  const [filter, setFilter] = useState({});
  const handleFiters = (e) => {
    const value = e.target.value;
    setFilter({
      ...filter,
      [e.target.name]: value,
    });
  };
  //Sprting
  const [sort, setSort] = useState("Newest");

  //to change title as soon as component mounts
  useEffect(() => {
    document.title = `PanaraIt - ${props.title}`;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container style={{ backgroundImage: `url(${mainbackground})` }}>
      <Announcments />
      <Navbar />
      <Title>{cat || "All Products"}</Title>
      <FilterContainer>
        {/* <Filter>
          <FilterText>Filter Products:</FilterText>
          <Select name="color" onChange={handleFiters}>
            <Options defaultValue>Color</Options>
            <Options>red</Options>
            <Options>green</Options>
            <Options>blue</Options>
            <Options>yallow</Options>
            <Options>black</Options>
            <Options>white</Options>
          </Select>
          <Select name="size" onChange={handleFiters}>
            <Options defaultValue>Size</Options>
            <Options>S</Options>
            <Options>M</Options>
            <Options>L</Options>
            <Options>XL</Options>
            <Options>XXL</Options>
            <Options>XXXL</Options>
          </Select>
        </Filter> */}
        <Filter>
          <FilterText>Sort Products:</FilterText>
          <Select onChange={(e) => setSort(e.target.value)}>
            <Options defaultValue>Newest</Options>
            <Options value="topRated">Top rated</Options>
            <Options value="toppurchased">Most Purchased</Options>
            <Options value="topreviewed">Most reviewed</Options>
            <Options value="price-desc">price(H T L)</Options>
            <Options value="price-asc">Price(L T H)</Options>
          </Select>
        </Filter>
      </FilterContainer>
      <Product cat={cat} filter={filter} sort={sort} />
      <NewsLetter />
      <Footer />
    </Container>
  );
}

export default ProductList;
